import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.svg';
import text from './doggy-daycare.png';
import analytics from './roost-analytics.png';
import campaign from './roost-campaigns.png';
import './LandingPage.css';

function LandingPage() {
    return (
        <div className="body">
            <nav>
                <div className="container">
                    <div className="logo-container">
                        <img className="header-logo" src={logo} alt="Logo" />
                        <h3 className="logo"><a href="#">Roost</a></h3>
                    </div>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#overview">Overview</a></li>
                        {/* <li><a href="#testimonials">Testimonials</a></li> */}
                    </ul>
                    <div className="nav-cta-container">
                        {/* <Link to="/login" className="cta">Log In</Link>
                        <Link to="/register" className="cta">Sign Up</Link> */}
                        <Link to="https://forms.gle/HAn7E69jsd9UfKgy8" className="cta">Join the waitlist!</Link>
                    </div>
                </div>
            </nav>
            <header>
                <div className="container">
                    <h1>Put Your Business</h1>
                    <h1><span className="highlight">On The Map 📍</span></h1>
                    <div className="sub">
                        <p> </p>
                        <p>📈 More Reviews Means More Business </p>
                        <p>🤩 2x Your Reviews in 30 Days</p>
                    </div>
                    <Link to="https://forms.gle/HAn7E69jsd9UfKgy8" className="main-cta">Join the Waitlist!</Link>
                </div>
            </header>
            <main>
                <section className="overview" id="overview">
                    <div className="container">
                        <div className="section">
                            <h2>💬 Ask Customers For Feedback</h2>
                            <img className="grid-img" src={text} alt="Grid" />
                        </div>
                        <div className="section">
                            <h2>Schedule SMS Campaigns <br></br> With Flexible Templates</h2>
                            <img className="grid-img grid-img-horizontal" src={campaign} alt="Add job" />
                        </div>
                        <div className="section">
                            <h2>Watch Your Reviews Grow</h2>
                            <img className="grid-img grid-img-horizontal" src={analytics} alt="Add job" />
                        </div>
                        <div className="section">
                            <h2>All with Roost ... Join the waitlist today!</h2>
                        </div>


                    </div>
                </section>
                {/* <section className="testimonials" id="testimonials">
                    <div className="container">
                        <h2>What Our Customers Say</h2>
                        <blockquote>
                            <p>"JobHawk has made my job hunt so much more organized and manageable."</p>
                            <p>"I never miss a deadline or forget about an application now!"</p>
                            <cite>A happy JobHawk user</cite>
                        </blockquote>
                    </div>
                </section> */}
            </main>
            <footer>
                <div className="container">
                    <p>&copy; 2023 Roost</p>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;
